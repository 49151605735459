import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import AppRouter from "./Components/Router/Router";
import AOS from "aos";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: false, 
    });

    const elements = document.querySelectorAll('.how-works-one');
    const centerSpans = document.querySelectorAll('.how-works-center span');

    const handleClick = (index) => {
      centerSpans[index].scrollIntoView({
        behavior: 'smooth',
      });
    };

    elements.forEach((element, index) => {
      element.addEventListener('click', () => handleClick(index));
    });

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('how-works-active');
        } else {
          entry.target.classList.remove('how-works-active');
        }
      });
    }, { threshold: 0.9 });

    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((element, index) => {
        element.removeEventListener('click', () => handleClick(index));
      });
      observer.disconnect();
    };
  }, [location]);
  return (
    <div className="App">
      <div>
        <Header />
      </div>

      <div>
        <AppRouter />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
