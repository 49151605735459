import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  // Toggle the state when the navbar toggle button is clicked
  const toggleNavbar = () => setIsNavOpen(!isNavOpen);

  // Close the navbar when a link is clicked
  const closeNavbar = () => setIsNavOpen(false);

  return (
    <div>
      <div className="header">
        <div className="header-nav">
          <div className="top-nav">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-9 col-sm-12 col-xs-12">
                  <div className="top-box1">
                    <div className="contact-number">
                      <a className="number-text">
                        <i className="fa-solid fa-phone" />
                        +91-73-749-47373
                      </a>
                      <a>
                        <i className="fa-solid fa-envelope" />{" "}
                        info@washwoosh.com
                      </a>
                    </div>
                    <div className="social-media-top">
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/washwooshIndia/">
                            <i className="fa-brands fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/washwooshindia/">
                            <i className="fa-brands fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/washwooshindia/">
                            <i className="fa-brands fa-linkedin-in" />
                          </a>
                        </li>
                        <li>
                          <a href="https://x.com/washwooshindia">
                            <i className="fa-brands fa-x-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/@WashWooshIndia">
                            <i className="fa-brands fa-youtube" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-3 col-sm-12 col-xs-12">
                  <div className="head-franchise">
                    <div className="book-mafia">
                      <Link to={"/contact"}>
                        <a className="btn head-btn">
                          <span>Book Now</span>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="men-nav">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <div className="logo">
                  <Link to={"/"}>
                    <a className="navbar-brand navbar-brand-one">
                      <img
                        className="img-fluid"
                        src="images/logo1.png"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration={2000}
                      />
                    </a>
                  </Link>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleNavbar}
                  aria-controls="navbarSupportedContent"
                  aria-expanded={isNavOpen ? "true" : "false"}
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className={`collapse navbar-collapse ${
                    isNavOpen ? "show" : ""
                  }`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item nav-item1">
                      <Link
                        style={{ textDecoration: "none" }}
                        className={location.pathname === "/" ? "active" : ""}
                        to={"/"}
                        onClick={() => {
                          closeNavbar();
                          handleLinkClick();
                        }}
                      >
                        <a
                          className={`nav-link nav-link-one ${
                            location.pathname === "/" ? "active" : ""
                          }`}
                        >
                          <span>Home</span>
                        </a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        className={
                          location.pathname === "/how-it-works" ? "active" : ""
                        }
                        to={"/how-it-works"}
                        onClick={() => {
                          closeNavbar();
                          handleLinkClick();
                        }}
                      >
                        <a
                          className={`nav-link nav-link-one ${
                            location.pathname === "/how-it-works"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span>How it works</span>
                        </a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        className={
                          location.pathname === "/services" ? "active" : ""
                        }
                        to={"/services"}
                        onClick={() => {
                          closeNavbar();
                          handleLinkClick();
                        }}
                      >
                        <a
                          className={`nav-link nav-link-one ${
                            location.pathname === "/services" ? "active" : ""
                          }`}
                        >
                          <span>Services</span>
                        </a>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
        <Link
          style={{ textDecoration: "none" }}
          className={location.pathname === "/blogs" ? "active" : ""}
          to={"/blogs"}
          onClick={() => {
            closeNavbar();
            handleLinkClick();
          }}
        >
          <a className={`nav-link nav-link-one ${location.pathname === "/blogs" ? "active" : ""}`}>
            <span>Blogs</span>
          </a>
        </Link>
      </li> */}
                    <li className="nav-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        className={
                          location.pathname === "/contact" ? "active" : ""
                        }
                        to={"/contact"}
                        onClick={() => {
                          closeNavbar();
                          handleLinkClick();
                        }}
                      >
                        <a
                          className={`nav-link nav-link-one ${
                            location.pathname === "/contact" ? "active" : ""
                          }`}
                        >
                          <span>Contact</span>
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
