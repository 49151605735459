import React from "react";

const Aboutus = () => {
  return (
    <div className="about-men">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-7">
            <div
              className="about-text"
              data-aos="fade-down"
              data-aos-duration={2000}
            >
              <h2>About Us</h2>
              <p className="welcome-text">
                {/* <b>Welcome To WashWoosh</b> */}
              </p>
              <p>
                Welcome to WashWoosh, where we believe that your car deserves
                the best care without any inconvenience ! As a premier mobile
                car washing company, we bring professional car cleaning services
                directly to your doorstep, ensuring your vehicle shines like new
                while you go about your day.
                <br />
                We understand that convenience is key in today’s fast-paced
                world. That’s why we offer flexible scheduling options to fit
                your busy lifestyle. Whether you need a quick wash or a detailed
                cleaning, WashWoosh is here to provide a reliable, efficient,
                and thorough service right at your location.
                <br />
                With our "WashWoosh App," you can easily schedule your service,
                track your wash, and even customize your cleaning
                preferences—all at your fingertips. We’re redefining the car
                wash experience, making it as seamless as possible.
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div
              className="banner-text banner-text-car text-end"
              data-aos="zoom-in-up"
              data-aos-duration={2000}
            >
              <img
                className="img-fluid"
                src="images/about-img.png"
                alt="Photo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
