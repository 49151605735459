import React from "react";
import { COMPANY_ID, TOKEN_SLUG } from "../../utils/constants";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { contact } from "../../Services/Contact.service";
import { toast } from "react-hot-toast";




const Contact = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
      contact: "",
      send: "Send Message",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("Name is required field")
        .matches(/^[a-zA-Z ]*$/, "Name should be string"),
      email: Yup.string()
        .required("Email is required field")
        .email()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Email is not valid"
        ),
      contact: Yup.string()
        .required("Phone is required field")
        .matches(/^\d{10}$/, "Please enter a valid 10-digit phone number."),
      subject: Yup.string().required("Subject is required field"),
      message: Yup.string().required("Message is required field"),
    }),

    onSubmit: (value,{resetForm, setSubmitting}) => {
        value={...value,company_id:COMPANY_ID}
        setSubmitting(true);
      contact(value)
        .then((res) => {
          toast.success("Thanks, Will update you soon");

          resetForm();
          setSubmitting(false);

          if (res.data.token) localStorage.setItem(TOKEN_SLUG, res.data.token);
        })
        .catch((err) => {
          console.error("error in contactus ", err);
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="middle">
      <div className="contact-men">
        <div className="container">
          <div className="heading-men">
            <h2>Keep In Touch With Us.</h2>
            <p>
              We have a dedicated support team ready to assist you if you need
              anything!
            </p>
          </div>
          <div className="row mt-4 align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="contact-left">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                  }}
                >
                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-text">
                        <label>Your Name*</label>
                        <input
                          className="input-btn"
                          type="text"
                          name="Name"
                          placeholder="First Name"
                          
                          value={formik.values.name}
                          onChange={(e) =>
                            formik.setFieldValue("name", e.target.value)
                          }
                        />
                        <div className="error-css"
                        >{formik.touched.name && formik.errors.name}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-text">
                        <label>Your Email*</label>
                        <input
                          className="input-btn"
                          type="text"
                          name="Name"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={(e) =>
                            formik.setFieldValue("email", e.target.value)
                          }
                        />
                        <div className="error-css">{formik.touched.email && formik.errors.email}</div>

                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-text">
                        <label>Your Contact Number*</label>
                        <input
                          className="input-btn"
                          type="text"
                          name="Name"
                          placeholder="Number"
                          value={formik.values.contact}
                          onChange={(e) =>
                            formik.setFieldValue("contact", e.target.value)
                          }
                        />
                        <div className="error-css">{formik.touched.contact && formik.errors.contact}</div>

                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-text">
                        <label>Subject*</label>
                        <input
                          className="input-btn"
                          type="text"
                          name="Name"
                          placeholder="Subject" 
                          value={formik.values.subject}
                          onChange={(e) =>
                            formik.setFieldValue("subject", e.target.value)
                          }
                        />
                        <div className="error-css">{formik.touched.subject && formik.errors.subject}</div>

                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-text">
                        <label>Your Message*</label>
                        <input
                          className="input-btn input-btn1"
                          type="text"
                          name="Name"
                          placeholder="Message...."
                          value={formik.values.message}
                          onChange={(e) =>
                            formik.setFieldValue("message", e.target.value)
                          }
                        />
                        <div className="error-css">
                        {formik.touched.message && formik.errors.message}
                            
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="book-mafia mt-2">
                        <button
                          className="btn head-btn head-btn1"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                           <span>
                            {formik.isSubmitting ? "Loading..." : "Send Message ⇾"}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="contact-right">
                <div className="working-hours-text">
                  <h2>Working Hours</h2>
                  <p>Monday to Saturday: 9 am to 10 pm</p>
                  <p>
                    <b>
                      Note that we also provide corporate deals and event
                      solutions.
                    </b>
                  </p>
                  <p>Write to us!</p>
                </div>
                <div className="working-hours-one">
                  <i className="fa-solid fa-phone" />
                  <h2>+91 7374947373</h2>
                </div>
                <div className="working-hours-one">
                  <i className="fa-solid fa-envelope" />
                  <h2>info@washwoosh.com</h2>
                </div>
                <div className="working-hours-one locationicon">
                  <i className="fa-solid fa-location-dot" />
                  <h2>
                    <b>Corporate Office:</b> Yogi tower, C-17, Mahalaxmi Nagar Rd, behind world trade park mall, D-Block, 
                     Malviya Nagar, Jaipur - 302017, Rajasthan
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12885.115790180049!2d75.78629498715819!3d26.851746500000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5d69950e079%3A0xe71d3d472f718bd6!2sSUUGAM%20TECHKNOW%20WORLD%20PRIVATE%20LIMITED!5e1!3m2!1sen!2sin!4v1729929022308!5m2!1sen!2sin"
            width="100%"
            height={450}
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Contact;
