import React from 'react'

const Terms = () => {
  return (
   <div className="middle">
  <div className="privacy-men">
    <div className="container">
      <div className="heading-men">
        <h2>Terms &amp; Conditions</h2>
        <p>We’re on a mission to deliver engaging, curated courses at a reasonable price.</p>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-12 col-md-12">
          <div className="contact-right">
            <div className="working-hours-text privacy-text">
              <h2>Services Provided</h2>
              <p>WashWoosh provides car detailing services including but not limited to exterior detailing, interior detailing, paint correction, ceramic coating, and specialty services as agreed upon with the customer. Customer has to handpick special services to which only the opted services shall be provided. Adjacent or complimenting services shall not be carried out.</p>
              <h2>Appointment Scheduling</h2>
              <p>Customers must schedule appointments in advance. Walk-in appointments may be accommodated based on availability and confirmation from our team.
              </p><h2>Cancellation, Refund and Rescheduling</h2>
              <p>Cancellation, refund, and rescheduling conditions shall comply with their respective policies mentioned.</p>
              <h2>Vehicle Condition</h2>
              <p>The real time representation of the services/ vehicle condition might differ from the graphical representations. Under all conditions we assure you that we shall take utmost care of any damages caused to your vehicle during the time of providing the service.</p>
              <h2>Payment</h2>
              <p>All subscriptions, and chosen special services shall only be confirmed and carried out on pre-paid models. Payments once paid (if needed) shall be processed for reversal steps in compliance to the company’s refund policy.</p>
              <h2>Liability</h2>
              <p>While we take utmost care in servicing your vehicle WashWoosh shall not be liable for any damage to or loss of personal belongings left inside the vehicle during detailing. Customers are requested to take out any personal belongings like sunglasses, wallets, currencies, cosmetics, documents, electronic gadgets, parking tickets or any other belongings. <br/>Upon completion of the services provided, WashWoosh shall not be held liable for any damages, defects, or malfunctions that may occur to the vehicle thereafter. The customer acknowledges that once the service is completed and the vehicle is returned, WashWoosh assumes no responsibility for any subsequent issues or failures that may arise, unless explicitly stated in a separate agreement. It is the customer’s responsibility to ensure proper maintenance and care of the vehicle following the completion of the service. </p>
              <h2>Completion Time</h2>
              <p>The time required to complete detailing services may vary depending on the type and condition of the vehicle. Customers will be informed of the estimated completion time upon scheduling and visual inspection.</p>
              <h2>Satisfaction Guarantee</h2>
              <p>We strive to achieve customer satisfaction with every service. If you are not satisfied with the results, please inform us immediately, or lately before 2 hours of the services availed and we will work to address your concerns. Natural occurrences being beyond human control, WashWoosh cannot assure for any happenings post service availing.</p>
              <h2>Safety and Security</h2>
              <p>Customers are responsible for ensuring that their vehicle is in a safe and drivable condition before and after detailing services. Since our services do not attend or interfere with any mechanical or electrical working of the vehicle, WashWoosh is not liable for any issues arising from the mechanical or operational condition of the vehicle during or after service providing.</p>
              <h2>Changes to Terms</h2>
              <p>WashWoosh reserves the right to update or modify these Terms and Conditions at any time. Customers will be notified of any changes.</p>
              <p>By scheduling an appointment with WashWoosh, you agree to abide by these Terms and Conditions. If you have any questions or concerns regarding our policies, please contact us.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Terms
