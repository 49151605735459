import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
        <div className="middle">
  <div className="privacy-men">
    <div className="container">
      <div className="heading-men">
        <h2>Privacy and Policy</h2>
        <p>We’re on a mission to deliver engaging, curated courses at a reasonable price.</p>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-12 col-md-12">
          <div className="contact-right">
            <div className="working-hours-text privacy-text">
              <h2>Payments</h2>
              <p>You agree to pay a one-time activation fee and a per-message rate to use the Services, with payments processed through your established payment method as per your Support Agreement. You commit to maintaining WashWoosh’s authorization to charge your selected payment method until all fees are paid in full, unless mutually agreed otherwise in writing. Real-time account status regarding text messages sent can be checked via Car Wash Administrator. Failure to pay fees may result in service interruption until all outstanding amounts are settled. WashWoosh is not liable for payment method errors or failures, including refusals to authorize charges. Customized plans may offer individual terms and billing cycles at our discretion, not publicly listed on our site.</p>
              <h2>Acceptable Use</h2>
              <p>WashWoosh reserves the right to assess and determine violations of its acceptable use policy and Terms at its discretion. Acceptable use of our Services encompasses activities such as promoting car wash discounts, unlimited packages, or referral programs, strictly for lawful purposes. Prohibited activities include actions that could adversely impact the availability, reliability, or stability of WashWoosh's Services, attempting to circumvent security measures, or engaging in illegal activities such as fraud. Violations also extend to misuse of copyrighted or trademarked material, unauthorized data collection, and sending unsolicited marketing messages. Any misuse or illegal activity may result in immediate termination of Services and may be subject to legal consequences under applicable laws.</p>
              <h2>Warranty Disclaimer</h2>
              <p>WashWoosh provides the services and all related content on an “as is” basis. washWoosh makes no representations or warranties of any kind, whether express, implied, or statutory. to the broadest extent permitted by law, washWoosh disclaims all warranties, including and without limitation, any implied warranties of merchantability, satisfactory quality, fitness for any particular purpose, title, and quiet enjoyment and any warranties arising out of any course of dealing or usage of trade with respect to the services. no verbal advice or written information given by washWoosh, its employees, licensors, licensees, affiliates, or agents or by any other third party shall create any warranty with respect to the services.</p>
              <h2>Indemnification</h2>
              <p>In addition to your obligations under your Support Agreement with WashWoosh, to the fullest extent allowed by applicable law, You hereby agree to defend, indemnify, and hold harmless WashWoosh and its business and technology partners, underlying technology creators, third-party suppliers, operators and providers, licensors, board members, officers, directors, shareholders, employees, distributors, resellers, affiliates, and agents from and against any damages (whether compensatory, exemplary, punitive, special, consequential, or incidental), losses, liabilities, judgments, fines, settlements, and expenses (including reasonable attorney, expert, and consultant fees and other legal expenses) in connection with any and all claims, allegations, governmental inquiries, demands, causes of action, lawsuits, or proceedings relating to or arising out of or in connection with Your use of the Services, including without limitation any claim or action arising out of or in connection with (i) any alleged act or omission by You that would constitute a breach of these Terms; (ii) any violation of any law, rule, or regulation concerning messaging alleged to have been committed through any use of your WashWoosh account or your use the Services; or (iii) any other use of WashWoosh's Services by You in any manner not authorized by these Terms, in violation of the restrictions herein, or in violation of applicable law.</p>
              <h2>Termination</h2>
              <p>You may terminate your use of the Services at any time by submitting a support ticket through WashWoosh’s user interface. WashWoosh requires a 30-day termination notice. Service may not be cancelled by any other method such as phone, email, or letter. Lack of activity alone does not automatically terminate your account, and you are responsible for service fees until you expressly cancel your account or until your data has been purged (whichever occurs first). In the absence of an explicit cancellation request by support ticket, you agree to pay any and all applicable fees through the date of WashWoosh's termination of your account access. You understand and agree that WashWoosh will make no refund of any fees whether or not the Services have been used. At any time, with or without notice, WashWoosh may terminate your use of the Services or disable your account in whole or in part at WashWoosh's sole discretion. WashWoosh shall bear no liability to you or any third party because of any such action.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      
    </div>
  )
}

export default PrivacyPolicy
